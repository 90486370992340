/*
    Scroll Animations Library
*/
var toBeAnimated;

var animations = [
	'in-place',
	'from-left',
	'from-right',
	'from-top',
	'from-bottom'
];

var checkScrollInterval;

setTimeout(initScrollAnimate, 500);

function initScrollAnimate() {
	// Initialize
	toBeAnimated = Array.from(document.querySelectorAll('.scroll-animation'));

	toBeAnimated.forEach(element => {
		animations.forEach(animation => {
			if (element.classList.contains(animation)) {
				element.setAttribute('data-animation', animation);
			}
		});
	});

	checkScrollInterval = setInterval(checkAnimationScroll, 25);
}

function scrollAnimate(
	element,
	animation,
	duration = 0.5,
	delay = 150,
	defaultTransition = 0
) {
	// Animate Element and remove references

	toBeAnimated.splice(toBeAnimated.indexOf(element), 1);

	element.style.transitionDuration = duration + 's';

	setTimeout(() => {
		element.classList.remove(animation);

		setTimeout(() => {
			element.style.transitionDuration = defaultTransition + 's';
		}, duration * 100);
	}, delay);
}

function checkAnimationScroll() {
	// Check elements
	let windowHeight = document.body.clientHeight;

	toBeAnimated.forEach(function (element) {
		let elementOffset = element.getBoundingClientRect().top;

		if (elementOffset - windowHeight <= 0) {
			let animation = element.getAttribute('data-animation');

			let duration;
			let delay;
			let defaultTransition;

			if (element.hasAttribute('data-duration')) {
				duration = element.getAttribute('data-duration');
			}

			if (element.hasAttribute('data-delay')) {
				delay = element.getAttribute('data-delay');
			}

			if (element.hasAttribute('data-defaultTransition')) {
				defaultTransition = element.getAttribute(
					'data-defaultTransition'
				);
			}

			scrollAnimate(
				element,
				animation,
				duration,
				delay,
				defaultTransition
			);
		}
	});

	if (toBeAnimated.length == 0) {
		clearInterval(checkScrollInterval);
	}
}
